import moment from 'moment';
import { ebpmsStatus } from '../helpers/constants';
import { getBPVThreshold, roundToXPlaces } from '../helpers/functions';

const getStatusFromBPV_TIP = (BPV, BPV8, trailerType) => {
  const threshold = getBPVThreshold(trailerType);
  if (BPV >= threshold.upper) {
    return ebpmsStatus.pass;
  }
  if (BPV8 === null || BPV8 === undefined) {
    if (BPV >= threshold.lower) {
      return ebpmsStatus.lowPerformance;
    }
    return ebpmsStatus.actionRequired;
  }
  if (BPV8 >= threshold.upper) {
    return ebpmsStatus.lowPerformance;
  }
  return ebpmsStatus.actionRequired;
};

export function getStatus(performanceBPV, performanceBPV8, trailerType) {
  if (performanceBPV === null || performanceBPV === undefined) {
    return ebpmsStatus.gatheringData;
  }
  const BPV = parseFloat(performanceBPV);
  const BPV8 = performanceBPV8;
  return getStatusFromBPV_TIP(BPV, BPV8, trailerType);
}

function getReasonsArray(reasons) {
  const res = [];
  // if (!reasons.hasestimatedslope) res.push('missing estimated slope')
  // if (!reasons.hassufficientload) res.push('load not sufficient')
  // if (reasons.selectionwithloadfilter) res.push('event is')
  if (!reasons.haslowlateralacceleration) res.push('high lateral acceleration');
  if (!reasons.haspositivedeceleration) res.push('deceleration is too small');
  if (!reasons.hasregularsampling) res.push('irregular sampling');
  if (!reasons.hassufficientduration) res.push('event duration too short');
  if (!reasons.isonflatroad) res.push('road not flat enough');
  if (!reasons.isusable) res.push('missing some data');
  if (!reasons.validabsstatus) res.push('invalid ABS status');
  if (!reasons.validretarderstatus) res.push('invalid retarder status');
  if (!reasons.validrssstatus) res.push('invalid RSS status');
  return res;
}

export function cleanBrakeEvents(events) {
  return events.map(event => {
    const {
      // fields used
      event_id: eventId,
      start_time: startTime,
      selection: isSelected,
      start_lat: startLatitude, // for map
      start_lon: startLongitude,
      avg_pressure: avgPressure, // for scratterplot
      avg_deceleration: avgDeceleration,
      start_speed: startSpeed, // for scratterplot tooltip
      estimated_slope: estimatedSlope,
      slope,
      distance_gps: distanceGps,
      duration,
      // reasons
      // has_estimated_slope: hasestimatedslope,
      // has_sufficient_load: hassufficientload,
      // selection_with_load_filter: selectionwithloadfilter,
      is_usable: isusable,
      has_sufficient_duration: hassufficientduration,
      has_positive_deceleration: haspositivedeceleration,
      has_low_lateral_acceleration: haslowlateralacceleration,
      has_regular_sampling: hasregularsampling,
      is_on_flat_road: isonflatroad,
      valid_abs_status: validabsstatus,
      valid_retarder_status: validretarderstatus,
      valid_rss_status: validrssstatus
    } = event;
    const reasons = getReasonsArray({
      // hasestimatedslope,
      // hassufficientload,
      // selectionwithloadfilter,
      haslowlateralacceleration,
      haspositivedeceleration,
      hasregularsampling,
      hassufficientduration,
      isonflatroad,
      isusable,
      validabsstatus,
      validretarderstatus,
      validrssstatus
    });
    return {
      // mapping
      eventId,
      startDatetime: moment.utc(+startTime),
      isSelected,
      selectable: true,
      startLatitude,
      startLongitude,
      avgPressure,
      avgDeceleration,
      startSpeed,
      estimatedSlope,
      slope,
      distanceGps,
      duration,
      reasons
    };
  });
}

export function cleanTelemetry(telemetry) {
  return telemetry.map(tel => {
    const {
      // fields used
      lat,
      lon,
      pcan,
      wheel_speed: wheelSpeed,
      datetime,
      nasa_elevation: nasaElevation
    } = tel;
    return {
      // mapping
      lat: lat && +lat,
      lon: lon && +lon,
      wheel_speed: wheelSpeed && +wheelSpeed,
      pcan: pcan && +pcan,
      datetime: datetime && moment.utc(+datetime),
      elevation: nasaElevation && +nasaElevation
    };
  });
}

// BPV8 fallback, if not provided by the Backend, use standard BPV * 1.25
export const getBPV8Value = (BPV8, BPV) => {
  if (BPV8 === null || BPV8 === undefined) {
    const fallback = parseFloat(BPV) * 1.25;
    return fallback;
  }
  return parseFloat(BPV8);
};

const getBPV8 = performance => ({
  performance_45_standard_8_0_nomargin: getBPV8Value(
    performance.performance_45_standard_8_0_nomargin,
    performance.performance_45_standard_nomargin
  ),
  performance_45_standard_8_0_margin01: getBPV8Value(
    performance.performance_45_standard_8_0_margin01,
    performance.performance_45_standard_margin01
  ),
  performance_45_standard_8_0_margin003: getBPV8Value(
    performance.performance_45_standard_8_0_margin003,
    performance.performance_45_standard_margin003
  ),
  performance_90_standard_8_0_nomargin: getBPV8Value(
    performance.performance_90_standard_8_0_nomargin,
    performance.performance_90_standard_nomargin
  ),
  performance_90_standard_8_0_margin01: getBPV8Value(
    performance.performance_90_standard_8_0_margin01,
    performance.performance_90_standard_margin01
  ),
  performance_90_standard_8_0_margin003: getBPV8Value(
    performance.performance_90_standard_8_0_margin003,
    performance.performance_90_standard_margin003
  ),

  // load algorithm
  performance_45_standard_load_8_0_nomargin: getBPV8Value(
    performance.performance_45_standard_load_8_0_nomargin,
    performance.performance_45_standard_load_nomargin
  ),
  performance_45_standard_load_8_0_margin01: getBPV8Value(
    performance.performance_45_standard_load_8_0_margin01,
    performance.performance_45_standard_load_margin01
  ),
  performance_45_standard_load_8_0_margin003: getBPV8Value(
    performance.performance_45_standard_load_8_0_margin003,
    performance.performance_45_standard_load_margin003
  ),
  performance_90_standard_load_8_0_nomargin: getBPV8Value(
    performance.performance_90_standard_load_8_0_nomargin,
    performance.performance_90_standard_load_nomargin
  ),
  performance_90_standard_load_8_0_margin01: getBPV8Value(
    performance.performance_90_standard_load_8_0_margin01,
    performance.performance_90_standard_load_margin01
  ),
  performance_90_standard_load_8_0_margin003: getBPV8Value(
    performance.performance_90_standard_load_8_0_margin003,
    performance.performance_90_standard_load_margin003
  )
});

const getLastIntervention = metadata => {
  if (!metadata?.owner_intervention) return;
  const lastIntervention = metadata.owner_intervention;
  let ladenRBTState = lastIntervention.ladenRBTState;
  // due to historical reasons, some ladenRBTState may be boolean instead of string
  // mapping the old field to ['pass','fail', 'relined']
  if (ladenRBTState === true) ladenRBTState = 'pass';
  if (ladenRBTState === false) ladenRBTState = 'fail';
  return { ...lastIntervention, ladenRBTState };
};

export function reconciliateTrailersWithPerformance(trailers, performanceMap, performanceKey, fallbackPerformanceKey) {
  return trailers.map(trailer => {
    const deviceId = trailer.deviceId || trailer.assetId;
    const trailerType = trailer?.metadata?.odr_trailer_type;
    const performance = performanceMap[deviceId];
    const trailerPerformance = buildTrailerPerformance(performance, trailerType);
    const newTrailer = {
      ...trailer,
      deviceId,
      tipNum: trailer.tipNum || trailer.internal_fleet_id,
      comment: trailer.metadata?.owner_comment,
      chassisNo: trailer.metadata?.ebs_vin,
      lastIntervention: getLastIntervention(trailer.metadata),
      ebpmsState: trailer.ebpms?.state,
      ebpmsStateChanged: trailer.ebpms?.changed,
      trailerType,
      isFallbackValues: false
    };
    Object.assign(newTrailer, trailerPerformance);
    // default selected Performance
    Object.assign(newTrailer, trailerPerformance[performanceKey]);

    if (!newTrailer.performance || (newTrailer.loadAlgorithm && !newTrailer.loadAlgorithm.performance)) {
      updateFallbackValues(newTrailer, trailerPerformance, fallbackPerformanceKey, trailerType);
    }

    return newTrailer;
  });
}

const updateFallbackValues = (newTrailer, trailerPerformance, fallbackPerformanceKey, trailerType) => {
  if (!newTrailer.performance) {
    newTrailer.performance = trailerPerformance?.[fallbackPerformanceKey]?.performance;
    newTrailer.status = getStatus(
      trailerPerformance?.[fallbackPerformanceKey]?.performance,
      trailerPerformance?.[fallbackPerformanceKey]?.performanceBPV8,
      trailerType
    );
    newTrailer.isFallbackValues = true;
  }

  if (newTrailer.loadAlgorithm && !newTrailer.loadAlgorithm.performance) {
    newTrailer.loadAlgorithm.performance = trailerPerformance[fallbackPerformanceKey]?.loadAlgorithm?.performance;
    newTrailer.loadAlgorithm.status = getStatus(
      trailerPerformance[fallbackPerformanceKey]?.loadAlgorithm?.performance,
      trailerPerformance[fallbackPerformanceKey]?.loadAlgorithm?.performanceBPV8,
      trailerType
    );
    newTrailer.loadAlgorithm.isFallbackValues = true;
  }
};

function buildTrailerPerformance(performance, trailerType) {
  if (!performance) return {};
  const {
    datetime_45_standard_nomargin,
    datetime_45_standard_margin01,
    datetime_45_standard_margin003,
    datetime_90_standard_nomargin,
    datetime_90_standard_margin01,
    datetime_90_standard_margin003,
    consecutive_days_fail_45_standard_margin01,
    consecutive_days_fail_45_standard_margin003,
    consecutive_days_fail_45_standard_nomargin,
    consecutive_days_fail_90_standard_margin003,
    consecutive_days_fail_90_standard_nomargin,
    consecutive_days_fail_90_standard_margin01,
    trendline_since_last_7_days_45_standard_nomargin,
    trendline_since_last_7_days_45_standard_margin01,
    trendline_since_last_7_days_45_standard_margin003,
    trendline_since_last_7_days_90_standard_nomargin,
    trendline_since_last_7_days_90_standard_margin01,
    trendline_since_last_7_days_90_standard_margin003,
    // BPV standard pressure
    performance_45_standard_nomargin,
    performance_45_standard_margin01,
    performance_45_standard_margin003,
    performance_90_standard_nomargin,
    performance_90_standard_margin01,
    performance_90_standard_margin003,

    // Load algorithm performances
    datetime_45_standard_load_nomargin,
    datetime_45_standard_load_margin01,
    datetime_45_standard_load_margin003,
    datetime_90_standard_load_nomargin,
    datetime_90_standard_load_margin01,
    datetime_90_standard_load_margin003,

    consecutive_days_fail_45_standard_load_nomargin,
    consecutive_days_fail_45_standard_load_margin01,
    consecutive_days_fail_45_standard_load_margin003,
    consecutive_days_fail_90_standard_load_nomargin,
    consecutive_days_fail_90_standard_load_margin01,
    consecutive_days_fail_90_standard_load_margin003,

    trendline_since_last_7_days_45_standard_load_nomargin,
    trendline_since_last_7_days_45_standard_load_margin01,
    trendline_since_last_7_days_45_standard_load_margin003,
    trendline_since_last_7_days_90_standard_load_nomargin,
    trendline_since_last_7_days_90_standard_load_margin01,
    trendline_since_last_7_days_90_standard_load_margin003,

    performance_45_standard_load_nomargin,
    performance_45_standard_load_margin01,
    performance_45_standard_load_margin003,
    performance_90_standard_load_nomargin,
    performance_90_standard_load_margin01,
    performance_90_standard_load_margin003
  } = performance;

  // BPV8 8.0 pressure
  const BPV8 = getBPV8(performance);

  return {
    nomargin45: {
      performance: performance_45_standard_nomargin,
      performanceBPV8: BPV8.performance_45_standard_8_0_nomargin,
      status: getStatus(performance_45_standard_nomargin, BPV8.performance_45_standard_8_0_nomargin, trailerType),
      datetime: +datetime_45_standard_nomargin,
      daysFailing: consecutive_days_fail_45_standard_nomargin,
      last7Days: trendline_since_last_7_days_45_standard_nomargin,
      loadAlgorithm: {
        datetime: datetime_45_standard_load_nomargin,
        last7Days: trendline_since_last_7_days_45_standard_load_nomargin,
        performance: performance_45_standard_load_nomargin,
        performanceBPV8: BPV8.performance_45_standard_load_8_0_nomargin,
        daysFailing: consecutive_days_fail_45_standard_load_nomargin,
        isFallbackValues: false,
        status: getStatus(
          performance_45_standard_load_nomargin,
          BPV8.performance_45_standard_load_8_0_nomargin,
          trailerType
        )
      }
    },
    nomargin90: {
      performance: performance_90_standard_nomargin,
      performanceBPV8: BPV8.performance_90_standard_8_0_nomargin,
      status: getStatus(performance_90_standard_nomargin, BPV8.performance_90_standard_8_0_nomargin, trailerType),
      datetime: +datetime_90_standard_nomargin,
      daysFailing: consecutive_days_fail_90_standard_nomargin,
      last7Days: trendline_since_last_7_days_90_standard_nomargin,
      loadAlgorithm: {
        datetime: datetime_90_standard_load_nomargin,
        last7Days: trendline_since_last_7_days_90_standard_load_nomargin,
        performance: performance_90_standard_load_nomargin,
        performanceBPV8: BPV8.performance_90_standard_load_8_0_nomargin,
        daysFailing: consecutive_days_fail_90_standard_load_nomargin,
        isFallbackValues: false,
        status: getStatus(
          performance_90_standard_load_nomargin,
          BPV8.performance_90_standard_load_8_0_nomargin,
          trailerType
        )
      }
    },
    margin0145: {
      performance: performance_45_standard_margin01,
      performanceBPV8: BPV8.performance_45_standard_8_0_margin01,
      status: getStatus(performance_45_standard_margin01, BPV8.performance_45_standard_8_0_margin01, trailerType),
      datetime: +datetime_45_standard_margin01,
      daysFailing: consecutive_days_fail_45_standard_margin01,
      last7Days: trendline_since_last_7_days_45_standard_margin01,
      loadAlgorithm: {
        datetime: datetime_45_standard_load_margin01,
        last7Days: trendline_since_last_7_days_45_standard_load_margin01,
        performance: performance_45_standard_load_margin01,
        performanceBPV8: BPV8.performance_45_standard_load_8_0_margin01,
        daysFailing: consecutive_days_fail_45_standard_load_margin01,
        isFallbackValues: false,
        status: getStatus(
          performance_45_standard_load_margin01,
          BPV8.performance_45_standard_load_8_0_margin01,
          trailerType
        )
      }
    },
    margin0190: {
      performance: performance_90_standard_margin01,
      performanceBPV8: BPV8.performance_90_standard_8_0_margin01,
      status: getStatus(performance_90_standard_margin01, BPV8.performance_90_standard_8_0_margin01, trailerType),
      datetime: +datetime_90_standard_margin01,
      daysFailing: consecutive_days_fail_90_standard_margin01,
      last7Days: trendline_since_last_7_days_90_standard_margin01,
      loadAlgorithm: {
        datetime: datetime_90_standard_load_margin01,
        last7Days: trendline_since_last_7_days_90_standard_load_margin01,
        performance: performance_90_standard_load_margin01,
        performanceBPV8: BPV8.performance_90_standard_load_8_0_margin01,
        daysFailing: consecutive_days_fail_90_standard_load_margin01,
        isFallbackValues: false,
        status: getStatus(
          performance_90_standard_load_margin01,
          BPV8.performance_90_standard_load_8_0_margin01,
          trailerType
        )
      }
    },
    margin00345: {
      performance: performance_45_standard_margin003,
      performanceBPV8: BPV8.performance_45_standard_8_0_margin003,
      status: getStatus(performance_45_standard_margin003, BPV8.performance_45_standard_8_0_margin003, trailerType),
      datetime: +datetime_45_standard_margin003,
      daysFailing: consecutive_days_fail_45_standard_margin003,
      last7Days: trendline_since_last_7_days_45_standard_margin003,
      loadAlgorithm: {
        datetime: datetime_45_standard_load_margin003,
        last7Days: trendline_since_last_7_days_45_standard_load_margin003,
        performance: performance_45_standard_load_margin003,
        performanceBPV8: BPV8.performance_45_standard_load_8_0_margin003,
        daysFailing: consecutive_days_fail_45_standard_load_margin003,
        isFallbackValues: false,
        status: getStatus(
          performance_45_standard_load_margin003,
          BPV8.performance_45_standard_load_8_0_margin003,
          trailerType
        )
      }
    },
    margin00390: {
      performance: performance_90_standard_margin003,
      performanceBPV8: BPV8.performance_90_standard_8_0_margin003,
      status: getStatus(performance_90_standard_margin003, BPV8.performance_90_standard_8_0_margin003, trailerType),
      datetime: +datetime_90_standard_margin003,
      daysFailing: consecutive_days_fail_90_standard_margin003,
      last7Days: trendline_since_last_7_days_90_standard_margin003,
      loadAlgorithm: {
        datetime: datetime_90_standard_load_margin003,
        last7Days: trendline_since_last_7_days_90_standard_load_margin003,
        performance: performance_90_standard_load_margin003,
        performanceBPV8: BPV8.performance_90_standard_load_8_0_margin003,
        daysFailing: consecutive_days_fail_90_standard_load_margin003,
        isFallbackValues: false,
        status: getStatus(
          performance_90_standard_load_margin003,
          BPV8.performance_90_standard_load_8_0_margin003,
          trailerType
        )
      }
    }
  };
}

export function cleanHistoricalPerformances(performances, isEnableLoadBPV = false) {
  return performances
    .filter(perf => perf.window_duration === 45 || perf.window_duration === 90)
    .map(
      ({
        // fields used
        start_datetime: startTime,
        end_datetime: endTime,
        performance: score,
        load_performance: { performance: loadPerformanceScore, error_margin: loadPerformanceErrorMargin } = {},
        performance_lower_bound: scoreLowerBound,
        num_events: numBrakeEvents,
        window_duration: windowSize,
        error_margin: errorMargin
      }) => ({
        // mapping
        startTime: startTime && moment.utc(+startTime),
        endTime: endTime && moment(+endTime),
        score: isEnableLoadBPV ? roundToXPlaces(loadPerformanceScore) : roundToXPlaces(score),
        scoreLowerBound,
        numBrakeEvents,
        windowSize,
        errorMargin: isEnableLoadBPV ? loadPerformanceErrorMargin : errorMargin
      })
    )
    .filter(perf => perf.score);
}
